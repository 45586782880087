import { useEffect, useRef, useState } from 'react';
import Navbar from '../../Section/Navbar';
import Footer from '../../Section/Footer';
import emailjs from '@emailjs/browser';

const Contact = () => {
    const [countryNames, setCountryNames] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('Pays');
    const [isOpen, setIsOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const form = useRef();
    const [error, setError] = useState('');

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const validateMobile = (mobile) => {
        const re = /^\d{10}$/;
        return re.test(String(mobile));
    };

    const sendEmail = (e) => {
        alert("hello");
        e.preventDefault();
        const email = form.current.client_email.value;
        const mobile = form.current.client_mobile.value;
        const name = form.current.nom.value;
        const message = form.current.message.value;

        if (!name || !email || !mobile || !message || selectedCountry === 'Pays') {
            setError('Please fill all fields.');
            return;
        }

        if (!validateEmail(email)) {
            setError('Invalid email format');
            return;
        }

        if (!validateMobile(mobile)) {
            setError('Invalid mobile number format');
            return;
        }

        setError('');

        emailjs
            .sendForm(
                'service_6gxly3m',
                'template_l6bf52g',
                form.current,
                'Z1CY8VLFD2SphbvvG'
            )
            .then(
                () => {
                    console.log('SUCCESS!');
                },
                (error) => {
                    console.log('FAILED...', error.text);
                }
            );
        e.target.reset();
    };

    useEffect(() => {
        fetch('https://restcountries.com/v3.1/all')
            .then(response => response.json())
            .then(data => {
                const countryList = data.map(country => country.name.common);
                setCountryNames(countryList);
            })
            .catch(error => {
                console.error('Error fetching countries:', error);
            });
    }, []);

    const handleCountryClick = (country) => {
        setSelectedCountry(country);
        setIsOpen(false);
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const filteredCountries = countryNames.filter(country =>
        country.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };
    console.log(error);

    return (
        <div>
            <Navbar />
            <h1 className="mt-24 mb-8 font-inter font-bold text-[40px] text-center text-[#F98B2A]">
                Nous Contactez
            </h1>
            <form ref={form} onSubmit={sendEmail}>
                <div className="mx-auto md:w-[658px] w-[100%] h-[516px] px-5 rounded-[35px] mt-10 shadow-2xl flex flex-col items-center py-7 justify-between">
                    {error && <p className='font-bold text-red-500 font-inter' > {error} </p>}
                    <div className="md:w-[587px] w-full h-[33px] flex justify-between items-center">
                        <input type="text" className="w-[150px] md:w-[258px] h-[33px] border-b-4 border-[#2BB673] outline-none" placeholder="Nom complet" name="nom" />
                        <input type="email" className="w-[150px] md:w-[258px] h-[33px] border-b-4 border-[#2BB673] outline-none" placeholder="Adresse email" name="client_email" />
                    </div>
                    <div className="md:w-[587px] w-full border-b-4 border-[#2BB673] flex justify-start items-center">
                        <input
                            type="text"
                            name="client_mobile"
                            className="font-inter font-light text-[20px] leading-[24px] text-black bg-transparent outline-none w-full"
                            placeholder="Votre numero de tel"
                        />
                    </div>

                    <div className="md:w-[587px] w-full h-[205px] shadow-md rounded-[10px] flex justify-start items-center overflow-hidden">
                        <textarea name="message" className="w-full h-full p-5 outline-none resize-none font-inter font-light text-[20px] leading-[24px] text-black">
                        </textarea>
                    </div>
                    <div className="md:w-[587px] w-full h-[47.38px] flex justify-between">
                        <div>
                            <div
                                className="w-[170px] h-[47.38px] bg-[#F98B2A] rounded-[7.06px] flex justify-center items-center cursor-pointer"
                                onClick={() => setIsOpen(!isOpen)}
                            >
                                <input
                                    type="text"
                                    className="font-inter font-bold text-[15.12px] leading-[18px] text-center text-white bg-transparent outline-none"
                                    value={selectedCountry}
                                    readOnly
                                    name="pays"
                                />
                            </div>
                            {isOpen && (
                                <div className="absolute z-40 bg-white border border-gray-300 mt-1 rounded shadow">
                                    <input
                                        type="text"
                                        className="w-full p-2 border-b border-gray-300 outline-none"
                                        placeholder="Rechercher un pays"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                    />
                                    <div className="max-h-60 overflow-y-auto">
                                        {filteredCountries.map((country, index) => (
                                            <div
                                                key={index}
                                                className="p-2 cursor-pointer hover:bg-gray-200"
                                                onClick={() => handleCountryClick(country)}
                                            >
                                                {country}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className="w-[170px] h-[47.38px] bg-[#2BB673] rounded-[7.06px] flex justify-center items-center cursor-pointer">
                            <button type="submit" className="font-inter font-bold text-[15.12px] leading-[18px] text-center text-white">
                                Envoyer
                            </button>
                        </div>
                    </div>
                </div>
            </form>
            <Footer />
        </div>
    );
};

export default Contact;
