import { Link } from 'react-router-dom';
// import pr1 from '../../img/pr1.jpg';
// import pr2 from '../../img/pr2.jpg';
// import pr3 from '../../img/pr3.jpg';
import leg1 from '../../img/leg/car.jpg';
import leg2 from '../../img/herbes/Armoise.jpg';
import leg3 from '../../img/fruits/Fraise.jpg';

const Produit = () => {
    return (
        <div id='Pr' className="h-fit md:w-[1317px] mx-auto mt-[139px] flex flex-col items-end ">
              <p className='text-center text-[15px] md:text-[50px] font-Poppins font-bold px-3 md:px-0  md:leading-[39px] text-[#218555] max-w-[1280px] mx-auto md:my-16 mb-7 uppercase  ' >Nos Produits</p>
            {/* <div className="m-0 flex flex-col items-end md:px-0 px-5">
                <p className=" md:text-[60px] text-[40px] font-bold md:leading-[73px] leading-[63px] text-start text-black font-inter" >Nos Produit</p>
                <div className="md:mt-2 mt-1 md:w-[400px] w-[250px] h-0 md:border-[5px] border-[3px] border-[#F98B2A]"></div>
            </div> */}
            <div className="w-full h-fit md:mt-[43px] mt-[40px]  mx-auto  flex md:flex-row flex-col md:flex-wrap items-center justify-center md:gap-x-4 gap-y-10  ">
                <Link to={`/catgLegume`} className='cursor-pointer z-40 w-full md:w-1/4 ' >
                    <div className="  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                        <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg1})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                        <div className=" w-full justify-center flex items-center flex-col ">
                            <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Légumes</p>
                        </div>
                    </div>
                </Link>
                <Link to={`/catgHerbes`} className='w-full md:w-1/4 cursor-pointer z-40 ' >
                    <div className=" h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                        <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg2})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                        <div className=" w-full justify-center flex items-center flex-col p-5 ">
                            <p className="text-[21.7167px] font-bold leading-[18px] text-[#000000] font-inter text-center mt-3 " >Herbes aromatiques fraîches</p>
                        </div>
                    </div>
                </Link>
                <Link to={`/catgFruit`} className='w-full md:w-1/4 cursor-pointer z-40 ' >
                    <div className=" h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                        <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg3})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                        <div className=" w-full justify-center flex items-center flex-col ">
                            <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Fruits</p>
                        </div>
                    </div>
                </Link>
            </div>
            {/* <Link to={`/pr`} className='w-[230px] h-[60px] bg-[#2BB673] rounded-[14px] flex items-center justify-center  mx-auto mt-10 cursor-pointer ' >
                <p className="text-[15px] font-bold leading-[36px] text-center text-[#FFFFFF] font-inter" >En savoir plus</p>
            </Link> */}

        </div>
    );
}

export default Produit;