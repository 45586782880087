import vector from '../../img/Vector.png';
import news1 from '../../img/News/promotionagriculturedurable.jpg';
import news2 from '../../img/News/lancementprojetagritech.jpg';
import news3 from '../../img/News/hydroponie.jpg';
import news4 from '../../img/News/formationAgriculteur.jpg';
import news5 from '../../img/News/securitealimentaire.jpg';
// import scroll from '../../Animation/scroll';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import more from '../../img/more.png';

const NewsInfo = [
    {
        Id: 'News1',
        image: news1,
        titel: "Promotion de l’Agriculture Durable",
        text: "Le gouvernement marocain encourage les agriculteurs à adopter des pratiques agricoles durables. Cela inclut l'utilisation de fertilisants naturels et de techniques d'agriculture biologique, visant à améliorer la qualité des fruits et légumes cultivés dans le pays.",
        text2: "Le Maroc s'engage dans une transition vers une agriculture plus durable et respectueuse de l'environnement. À travers des initiatives gouvernementales et des partenariats avec des organisations internationales, le pays encourage les agriculteurs à adopter des pratiques agricoles innovantes et écologiques. Cela inclut l'utilisation de fertilisants naturels, la diversification des cultures pour améliorer la biodiversité, et la réduction des pesticides chimiques. L'agriculture biologique, en particulier, est fortement promue pour garantir des produits de qualité tout en protégeant les sols et les ressources en eau. Ce mouvement vers l'agriculture durable vise non seulement à répondre aux exigences des marchés internationaux, mais aussi à améliorer les conditions de vie des petits exploitants agricoles et à renforcer la résilience face aux changements climatiques.",
    },
    {
        Id: 'News2',
        image: news2,
        titel: "Lancement de Projets Agritech au Maroc",
        text: "Le Maroc intensifie ses efforts en agritech avec le lancement de nouveaux projets visant à moderniser l’agriculture. Ces initiatives incluent l’intégration de drones pour la surveillance des cultures et des systèmes d'irrigation intelligents pour optimiser l’utilisation de l’eau.",
        text2:"Dans sa quête pour moderniser le secteur agricole, le Maroc investit massivement dans l'agrinnovation, en lançant des projets d’agritech à travers tout le pays. Ces initiatives visent à intégrer les dernières technologies dans l'agriculture, notamment l'utilisation de drones pour surveiller les champs et identifier rapidement les problèmes, tels que les parasites ou le manque d’eau. Les systèmes d'irrigation intelligents, basés sur des capteurs et des algorithmes, permettent aux agriculteurs d'économiser de l'eau tout en augmentant la productivité. Ces projets ne se limitent pas seulement à l’amélioration des rendements, ils cherchent aussi à promouvoir une gestion plus efficiente des ressources naturelles et à réduire les coûts de production. L'agrinnovation est perçue comme un levier essentiel pour transformer l'agriculture marocaine, lui permettant de mieux répondre aux défis climatiques et économiques."
    },
    {
        Id: 'News3',
        image: news3,
        titel: "Investissements dans l'Hydroponie",
        text: "Avec l'essor de l'hydroponie, le Maroc se positionne comme un acteur clé dans la production de légumes frais. Cette méthode permet de cultiver des plantes sans sol, en utilisant moins d'eau et en maximisant l'espace, ce qui est idéal pour les zones urbaines.",
        text2:"L’hydroponie est une méthode de culture innovante qui permet de cultiver des plantes sans sol, en utilisant des solutions nutritives à base d’eau. Au Maroc, cette technique connaît un essor rapide, notamment en réponse aux défis liés à la raréfaction des terres arables et des ressources en eau. En investissant dans des systèmes hydroponiques, le pays vise à améliorer la production agricole, en particulier dans les zones urbaines où l'espace est limité. Cette méthode permet également de produire des légumes frais tout au long de l'année, indépendamment des saisons, ce qui est crucial pour renforcer la sécurité alimentaire. Les projets hydroponiques sont également une réponse aux changements climatiques, car ils consomment jusqu'à 90 % moins d'eau que l'agriculture traditionnelle. Le Maroc se positionne ainsi comme un leader en matière d’agriculture innovante, tout en répondant aux besoins croissants en produits frais sur le marché local et à l'exportation.",
    },
    {
        Id: 'News4',
        image: news4,
        titel: "Formation des Agriculteurs aux Nouvelles Technologies",
        text: "Des programmes de formation sont mis en place pour aider les agriculteurs à intégrer les nouvelles technologies agricoles. Ces formations portent sur l'utilisation de capteurs et de logiciels de gestion pour optimiser les rendements et réduire les coûts.",
        text2:"Dans le cadre de la modernisation du secteur agricole marocain, des programmes de formation continue sont offerts aux agriculteurs pour les familiariser avec les nouvelles technologies. Ces formations incluent l’utilisation de capteurs pour surveiller les conditions des sols et des cultures, ainsi que des logiciels de gestion agricole pour optimiser les opérations. Les agriculteurs apprennent à intégrer des drones pour surveiller les grandes surfaces agricoles et des systèmes d'irrigation intelligents pour une meilleure gestion de l'eau. Ces technologies permettent non seulement d'augmenter les rendements et de réduire les coûts, mais elles contribuent également à la durabilité en minimisant les pertes et en maximisant l'efficacité. Ces programmes, soutenus par le gouvernement et des partenaires privés, visent à doter les agriculteurs des outils nécessaires pour relever les défis du changement climatique et répondre aux demandes des marchés modernes.",
    },
    {
        Id: 'News5',
        image: news5,
        titel: "Engagement pour la Sécurité Alimentaire",
        text: "Le Maroc s'engage à renforcer sa sécurité alimentaire en augmentant la production locale de fruits et légumes. Les initiatives visent à réduire la dépendance aux importations et à garantir un approvisionnement stable tout au long de l'année.",
        text2:"Face aux défis mondiaux de la sécurité alimentaire, le Maroc a pris des mesures significatives pour renforcer son autosuffisance en matière de production agricole. Des initiatives sont en cours pour augmenter la production locale de fruits et légumes, réduisant ainsi la dépendance aux importations. Le gouvernement marocain encourage les agriculteurs à adopter des techniques agricoles modernes et durables, comme l’hydroponie et l’irrigation goutte-à-goutte, afin de maximiser la productivité tout en préservant les ressources naturelles. En outre, des investissements sont faits dans les infrastructures agricoles pour faciliter la distribution des produits frais, assurant ainsi un approvisionnement stable tout au long de l’année. Cet engagement fort envers la sécurité alimentaire s'inscrit dans une stratégie plus large de développement durable, visant à garantir que le Maroc puisse répondre aux besoins croissants de sa population et aux demandes du marché international.",
    },
]

const News = () => {
    const scrollRef = useRef(null);

    const scrollLeft = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: -370, behavior: 'smooth' });
        }
    };
    const scrollRight = () => {
        if (scrollRef.current) {
            scrollRef.current.scrollBy({ left: 370, behavior: 'smooth' });
        }
    };

    return (
        <div className=" h-fit w-[390px] md:w-[1317px] mx-auto mt-[39px] p-5 md:p-0 my-10 ">
            <div className="m-0 flex flex-col items-start my-10">
                <p className="md:text-[50px] text-[30px] font-bold md:leading-[73px] leading-[63px] text-start text-black font-inter">MARONEWS</p>
                <div className="md:mt-2 mt-1 md:w-[297px] w-[250px] h-0 md:border-[5px] border-[3px] border-[#F98B2A]"></div>
            </div>
            <div className=" w-full ml-0  flex-nowrap md:w-[1183px] h-[500px] flex mt-5 mx-auto relative justify-center overflow-x-auto ">
                <img src={vector} className=' absolute left-0 top-52 z-20 cursor-pointer h-[55.47px] items-center justify-center my-auto mr-4 ' onClick={scrollLeft} alt="" />
                <div className="h-full flex z-10 items-start scrollable-divs " ref={scrollRef}>
                    {/* First set of content */}

                    {NewsInfo.map((news) => (
                        <div className="min-w-[350px] mr-5 relative h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                            <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${news.image})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                            <div className=" w-full justify-center flex items-center flex-col p-5 ">
                                <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter">{news.titel}</p>
                            </div>
                            <Link to={`/News/${news.Id}`} className=''
                                state={{
                                    id: news.Id,
                                    title: news.titel,
                                    text: news.text,
                                    image: news.image,
                                    text2:news.text2
                                }}
                            >
                                <div className=" absolute bottom-0 right-0 h-12 w-12  flex items-center justify-center ">
                                    <img src={more} className=' w-[60%] h-auto ' alt="" />
                                </div>
                            </Link>
                        </div>


                    ))}

                    {/* <div className=" min-w-[376.24px] h-[403.38px]">
                        <div className="bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${news1})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter">Fresh vegetables round the clock in Bad Blumau</p>
                        <div className="cursor-pointer mt-2">
                            <p className="text-[15.1267px] font-bold leading-[18px] text-[#2BB673] font-inter">Discover The full story</p>
                            <div className="w-[180px] h-0 bg-[#2BB673] border-[3.02534px] border-[#2BB673]"></div>
                        </div>
                    </div>
                    <div className="min-w-[376.24px] h-[403.38px] ml-2">
                        <div className="bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${news2})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter">Fresh vegetables round the clock in Bad Blumau</p>
                        <div className="cursor-pointer mt-2">
                            <p className="text-[15.1267px] font-bold leading-[18px] text-[#2BB673] font-inter">Discover The full story</p>
                            <div className="w-[180px] h-0 bg-[#2BB673] border-[3.02534px] border-[#2BB673]"></div>
                        </div>
                    </div>
                    <div className="min-w-[376.24px] h-[403.38px] ml-2">
                        <div className="bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${news3})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter">Fresh vegetables round the clock in Bad Blumau</p>
                        <div className="cursor-pointer mt-2">
                            <p className="text-[15.1267px] font-bold leading-[18px] text-[#2BB673] font-inter">Discover The full story</p>
                            <div className="w-[180px] h-0 bg-[#2BB673] border-[3.02534px] border-[#2BB673]"></div>
                        </div>
                    </div>


                    <div className="min-w-[376.24px] h-[403.38px] ml-2">
                        <div className="bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${news1})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter">Fresh vegetables round the clock in Bad Blumau</p>
                        <div className="cursor-pointer mt-2">
                            <p className="text-[15.1267px] font-bold leading-[18px] text-[#2BB673] font-inter">Discover The full story</p>
                            <div className="w-[180px] h-0 bg-[#2BB673] border-[3.02534px] border-[#2BB673]"></div>
                        </div>
                    </div>
                    <div className="min-w-[376.24px] h-[403.38px] ml-2">
                        <div className="bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${news2})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter">Fresh vegetables round the clock in Bad Blumau</p>
                        <div className="cursor-pointer mt-2">
                            <p className="text-[15.1267px] font-bold leading-[18px] text-[#2BB673] font-inter">Discover The full story</p>
                            <div className="w-[180px] h-0 bg-[#2BB673] border-[3.02534px] border-[#2BB673]"></div>
                        </div>
                    </div>
                    <div className="min-w-[376.24px] h-[403.38px] ml-2">
                        <div className="bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${news3})`, backgroundSize: 'cover', backgroundPosition: 'center' }}></div>
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter">Fresh vegetables round the clock in Bad Blumau</p>
                        <div className="cursor-pointer mt-2">
                            <p className="text-[15.1267px] font-bold leading-[18px] text-[#2BB673] font-inter">Discover The full story</p>
                            <div className="w-[180px] h-0 bg-[#2BB673] border-[3.02534px] border-[#2BB673]"></div>
                        </div>
                    </div> */}
                </div>
                <img src={vector} className='absolute right-0 top-52 z-20 cursor-pointer h-[55.47px] items-center justify-center my-auto ml-4 rotate-180 ' onClick={scrollRight} alt="" />
            </div>
        </div>
    );
}

export default News;
