import { useEffect } from "react";
import Footer from "../../../Section/Footer";
import Navbar from "../../../Section/Navbar";
import leg1 from '../../../img/fruits/Fraise.jpg';
import leg2 from '../../../img/fruits/Framboise.jpg';
import leg3 from '../../../img/fruits/Prune.jpg';
import leg4 from '../../../img/fruits/Myrtille.jpg';
import leg5 from '../../../img/fruits/Raisin.jpg';
import leg6 from '../../../img/fruits/Orange.jpg';
import leg7 from '../../../img/fruits/Grenadine.jpg';
import leg8 from '../../../img/fruits/Citron.jpg';
import leg9 from '../../../img/fruits/Clémentine.jpg';

import { Link } from "react-router-dom";

const legumes = [
    {
        Id: '16',
        image: leg1,
        nom: "Fraise",
        validity: [
            {
             mounth: 'Janvier',
             valid: true,   
            },
            {
             mounth: 'Février',
             valid: true,   
            },
            {
             mounth: 'Mars',
             valid: true,   
            },
            {
             mounth: 'Avril',
             valid: true,   
            },
            {
             mounth: 'Mai',
             valid: true,   
            },
            {
             mounth: 'Juin',
             valid: false,   
            },
            {
             mounth: 'Juillet',
             valid: false,   
            },
            {
             mounth: 'Août',
             valid: false,   
            },
            {
             mounth: 'Septembre',
             valid: false,   
            },
            {
             mounth: 'Octobre',
             valid: false,   
            },
            {
             mounth: 'Novembre',
             valid: true,   
            },
            {
             mounth: 'Décembre',
             valid: true,   
            },
        ]
    },
    {
        Id: '17',
        image: leg2,
        nom: "Framboise",
        validity: [
            {
             mounth: 'Janvier',
             valid: true,   
            },
            {
             mounth: 'Février',
             valid: true,   
            },
            {
             mounth: 'Mars',
             valid: true,   
            },
            {
             mounth: 'Avril',
             valid: true,   
            },
            {
             mounth: 'Mai',
             valid: true,   
            },
            {
             mounth: 'Juin',
             valid: true,   
            },
            {
             mounth: 'Juillet',
             valid: false,   
            },
            {
             mounth: 'Août',
             valid: false,   
            },
            {
             mounth: 'Septembre',
             valid: false,   
            },
            {
             mounth: 'Octobre',
             valid: false,   
            },
            {
             mounth: 'Novembre',
             valid: true,   
            },
            {
             mounth: 'Décembre',
             valid: true,   
            },
        ]
    },
    {
        Id: '18',
        image: leg3,
        nom: "Prune",
        validity: [
            {
             mounth: 'Janvier',
             valid: false,   
            },
            {
             mounth: 'Février',
             valid: false,   
            },
            {
             mounth: 'Mars',
             valid: false,   
            },
            {
             mounth: 'Avril',
             valid: false,   
            },
            {
             mounth: 'Mai',
             valid: false,   
            },
            {
             mounth: 'Juin',
             valid: true,   
            },
            {
             mounth: 'Juillet',
             valid: true,   
            },
            {
             mounth: 'Août',
             valid: true,   
            },
            {
             mounth: 'Septembre',
             valid: true,   
            },
            {
             mounth: 'Octobre',
             valid: false,   
            },
            {
             mounth: 'Novembre',
             valid: false,   
            },
            {
             mounth: 'Décembre',
             valid: false,   
            },
        ]
    },
    {
        Id: '19',
        image: leg4,
        nom: "Myrtille",
        validity: [
            {
             mounth: 'Janvier',
             valid: false,   
            },
            {
             mounth: 'Février',
             valid: false,   
            },
            {
             mounth: 'Mars',
             valid: true,   
            },
            {
             mounth: 'Avril',
             valid: true,   
            },
            {
             mounth: 'Mai',
             valid: true,   
            },
            {
             mounth: 'Juin',
             valid: true,   
            },
            {
             mounth: 'Juillet',
             valid: false,   
            },
            {
             mounth: 'Août',
             valid: false,   
            },
            {
             mounth: 'Septembre',
             valid: false,   
            },
            {
             mounth: 'Octobre',
             valid: false,   
            },
            {
             mounth: 'Novembre',
             valid: false,   
            },
            {
             mounth: 'Décembre',
             valid: false,   
            },
        ]
    },
    {
        Id: '20',
        image: leg5,
        nom: "Raisin",
        validity: [
            {
             mounth: 'Janvier',
             valid: false,   
            },
            {
             mounth: 'Février',
             valid: false,   
            },
            {
             mounth: 'Mars',
             valid: false,   
            },
            {
             mounth: 'Avril',
             valid: false,   
            },
            {
             mounth: 'Mai',
             valid: false,   
            },
            {
             mounth: 'Juin',
             valid: true,   
            },
            {
             mounth: 'Juillet',
             valid: true,   
            },
            {
             mounth: 'Août',
             valid: true,   
            },
            {
             mounth: 'Septembre',
             valid: true,   
            },
            {
             mounth: 'Octobre',
             valid: false,   
            },
            {
             mounth: 'Novembre',
             valid: false,   
            },
            {
             mounth: 'Décembre',
             valid: false,   
            },
        ]
    },
    {
        Id: '21',
        image: leg6,
        nom: "Orange",
        validity: [
            {
                mounth: 'Janvier',
                valid: true,
            },
            {
                mounth: 'Février',
                valid: true,
            },
            {
                mounth: 'Mars',
                valid: true,
            },
            {
                mounth: 'Avril',
                valid: true,
            },
            {
                mounth: 'Mai',
                valid: true,
            },
            {
                mounth: 'Juin',
                valid: true,
            },
            {
                mounth: 'Juillet',
                valid: true,
            },
            {
                mounth: 'Août',
                valid: true,
            },
            {
                mounth: 'Septembre',
                valid: true,
            },
            {
                mounth: 'Octobre',
                valid: true,
            },
            {
                mounth: 'Novembre',
                valid: true,
            },
            {
                mounth: 'Décembre',
                valid: true,
            },
        ]
    },
    {
        Id: '22',
        image: leg7,
        nom: "Grenadine",
        validity: [
            {
             mounth: 'Janvier',
             valid: false,   
            },
            {
             mounth: 'Février',
             valid: false,   
            },
            {
             mounth: 'Mars',
             valid: false,   
            },
            {
             mounth: 'Avril',
             valid: false,   
            },
            {
             mounth: 'Mai',
             valid: false,   
            },
            {
             mounth: 'Juin',
             valid: false,   
            },
            {
             mounth: 'Juillet',
             valid: false,   
            },
            {
             mounth: 'Août',
             valid: false,   
            },
            {
             mounth: 'Septembre',
             valid: true,   
            },
            {
             mounth: 'Octobre',
             valid: true,   
            },
            {
             mounth: 'Novembre',
             valid: true,   
            },
            {
             mounth: 'Décembre',
             valid: true,   
            },
        ]
    },
    {
        Id: '23',
        image: leg8,
        nom: "Citron",
        validity: [
            {
                mounth: 'Janvier',
                valid: true,
            },
            {
                mounth: 'Février',
                valid: true,
            },
            {
                mounth: 'Mars',
                valid: true,
            },
            {
                mounth: 'Avril',
                valid: true,
            },
            {
                mounth: 'Mai',
                valid: true,
            },
            {
                mounth: 'Juin',
                valid: true,
            },
            {
                mounth: 'Juillet',
                valid: true,
            },
            {
                mounth: 'Août',
                valid: false,
            },
            {
                mounth: 'Septembre',
                valid: false,
            },
            {
                mounth: 'Octobre',
                valid: false,
            },
            {
                mounth: 'Novembre',
                valid: true,
            },
            {
                mounth: 'Décembre',
                valid: true,
            },
        ]
    },
    {
        Id: '24',
        image: leg9,
        nom: "Clémentine",
        validity: [
            {
             mounth: 'Janvier',
             valid: true,   
            },
            {
             mounth: 'Février',
             valid: false,   
            },
            {
             mounth: 'Mars',
             valid: false,   
            },
            {
             mounth: 'Avril',
             valid: false,   
            },
            {
             mounth: 'Mai',
             valid: false,   
            },
            {
             mounth: 'Juin',
             valid: true,   
            },
            {
             mounth: 'Juillet',
             valid: true,   
            },
            {
             mounth: 'Août',
             valid: true,   
            },
            {
             mounth: 'Septembre',
             valid: true,   
            },
            {
             mounth: 'Octobre',
             valid: true,   
            },
            {
             mounth: 'Novembre',
             valid: true,   
            },
            {
             mounth: 'Décembre',
             valid: true,   
            },
        ]
    },
];

const Legume = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="">
            <Navbar className="fixed top-0 w-full z-50 " />
            <p className='font-inter font-bold text-[40px] text-center leading-[73px] text-[#2BB673] z-20 mt-28 uppercase animate-slideUp  ' >Fruits</p>
            <div className=" w-[90%] mx-auto h-fit mt-14 flex flex-wrap justify-center gap-x-4 gap-y-10 ">
            {legumes.map((legume) => {
                    return (
                        <Link className="md:w-1/4 w-full " to={'/pr-info'}
                            state={
                                {
                                    id: legume.Id,
                                    image: legume.image,
                                    nom: legume.nom,
                                    valid: legume.validity,
                                    cat:"Fruits"
                                }
                            }
                        >
                            <div key={legume.Id} className=" h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                                <div className=" bg-white w-full n h-[308.03px] flex justify-center items-start " >
                                    <img className=" m-auto    h-auto  w-full" src={legume.image} alt="" />
                                </div>
                                <div className="  w-full justify-center flex items-center flex-col ">
                                    <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >{legume.nom}</p>
                                </div>
                            </div>
                        </Link>
                    );
                })}
                {/* <Link to={`/pr-info`} className=' md:w-1/4 w-full ' >
                    <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                        <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg1})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                        <div className=" w-full justify-center flex items-center flex-col ">
                            <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                        </div>
                    </div>
                </Link>

                <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                    <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg2})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                    <div className=" w-full justify-center flex items-center flex-col ">
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                    </div>
                </div>
                <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                    <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg3})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                    <div className=" w-full justify-center flex items-center flex-col ">
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                    </div>
                </div>
                <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                    <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg3})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                    <div className=" w-full justify-center flex items-center flex-col ">
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                    </div>
                </div>
                <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                    <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg1})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                    <div className=" w-full justify-center flex items-center flex-col ">
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                    </div>
                </div>
                <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                    <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg2})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                    <div className=" w-full justify-center flex items-center flex-col ">
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                    </div>
                </div>
                <div className="md:w-1/4 w-full  h-[403.38px] rounded-xl overflow-hidden transition-all duration-300 ease-in-out translate-y-0 hover:-translate-y-10 hover:shadow-2xl">
                    <div className=" bg-black w-full h-[308.03px]" style={{ backgroundImage: `url(${leg3})`, backgroundSize: 'cover', backgroundPosition: 'center' }} ></div>
                    <div className=" w-full justify-center flex items-center flex-col ">
                        <p className="text-[21.7167px] font-bold leading-[26px] text-[#000000] font-inter text-center mt-5 " >Name product</p>
                    </div>
                </div> */}

            </div>
            <Footer />
        </div>
    );
}

export default Legume;