import pr1 from '../../img/dashbord/image00003.jpeg';
import pr2 from '../../img/dashbord/image00004.jpeg';
import pr3 from '../../img/dashbord/image00002.jpeg';
import { Link } from 'react-router-dom';



const Projets = () => {
    return (
        <div id='Ser' className="h-fit md:w-[1317px] w-[390px] mx-auto md:mt-[139px] flex flex-col items-start p-5 md:p-0 ">
              <p className='text-center text-[15px] md:text-[50px] font-Poppins font-bold px-3 md:px-0  md:leading-[39px] text-[#218555] max-w-[1280px] mx-auto my-16 uppercase  ' >Nos Projets</p>
            {/* <div className="m-0 flex flex-col items-start">
                <p className="md:text-[60px] text-[40px] font-bold md:leading-[73px] leading-[63px] text-start text-black font-inter" >Nos Services</p>
                <div className="md:mt-2 mt-1 md:w-[400px] w-[250px] h-0 md:border-[5px] border-[3px] border-[#F98B2A]"></div>
            </div> */}
            <Link to={'/Hydroponie'} className='w-full shadow-2xl' >
                <div className=" md:h-[408px] h-[225px] bg-black md:mt-[28px] mt-[18px] relative flex items-center " style={{ backgroundImage: `url(${pr1})`, backgroundSize: 'cover', backgroundPosition: 'center' }} >
                    <div className="w-full h-full absolute top-0 z-10 bg-[#F98B2A] opacity-20"></div>
                    <p className="md:text-[60px] text-[20px] font-bold md:leading-[73px] leading-[25px] text-center text-[#FFFFFF] font-inter m-auto z-20 " >Hydroponie</p>
                </div>
            </Link>
            <Link to={'/Compostage'} className='w-full shadow-2xl' >
                <div className="  md:h-[408px] h-[225px] bg-black md:mt-[28px] mt-[18px] relative flex items-center " style={{ backgroundImage: `url(${pr2})`, backgroundSize: 'cover', backgroundPosition: 'center' }} >
                    <div className="w-full h-full absolute top-0 z-10 bg-[#F98B2A] opacity-20"></div>
                    <p className="md:text-[60px] text-[20px] font-bold md:leading-[73px] leading-[25px] text-center text-[#FFFFFF] font-inter m-auto z-20 " >Compostage et gestion des déchets organiques</p>
                </div>
            </Link>
            <Link to={'/Agriculture'} className=' w-full shadow-2xl' >
                <div className="md:h-[408px] h-[225px] bg-black md:mt-[28px] mt-[18px] relative flex items-center " style={{ backgroundImage: `url(${pr3})`, backgroundSize: 'cover', backgroundPosition: 'center' }} >
                    <div className="w-full h-full absolute top-0 z-10 bg-[#F98B2A] opacity-20"></div>
                    <p className="md:text-[60px] text-[20px] font-bold md:leading-[73px] leading-[25px] text-center text-[#FFFFFF] font-inter m-auto z-20  " >Agriculture de Précision par Drones</p>
                </div>
            </Link>

            
        </div>
    );
}

export default Projets;